<template>

  <WeekViewActionsEdit :weekid="week.id" :owner="diary.item_user.id" :diarylink="diary.link" @remove="removeWeek"/>      

  <WeekViewGallery :data="week" />
  
  <WeekViewConditions 
    :days="week.days"
    :faza="week.faza"
    :props="week.props"
    :data="week.item_vegetation" />

  <WeekViewNutrients :nutrients="week.item_vegetation.items_nutrient" />

  <WeekViewMethods :items-method="week.item_vegetation.items_method" />

  <WeekViewComment :week="week" :user="diary.item_user"/> 

</template>

<script setup>

const emits = defineEmits(['removeweek', 'updateweek']);

const props = defineProps({
  week: {
    type: Object, 
    required: true,
  },
  diary: {
    type: Object, 
    required: true,
  },
});


const removeWeek = () => {
  emits('removeweek');
}
  
</script>

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
