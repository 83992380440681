<template>
  
  <div class="product_review">

    <div class="divider">
      {{ $t('harvest_view_strain') }}            
    </div>

    <div class="seed_box">
 
      <div class="brand">

        <img class="lg" :src="data.item_harvest.item_review_seed.item_brand.logo_s">
        
        <div class="str_box">
          <div class="header">{{ data.item_harvest.item_review_seed.item_product.name }}</div>
          <div class="meta">
            {{ data.item_harvest.item_review_seed.item_brand.name }}
          </div>
          <div class="rate">
            <UiRating
              :cnt="10"
              :vl="data.item_harvest.item_review_seed.n_general"
              :editable="false"
              />
          </div>
        </div> 
 
      </div>

      <div class="grow" v-if="data?.item_harvest?.item_review_seed?.props?.growing">
        <div class="header">{{ $t('harvest_view_growing_it') }}</div>
        <div>          
          {{ $constants.harvestGrowing[data.item_harvest.item_review_seed.props.growing] ? $t($constants.harvestGrowing[data.item_harvest.item_review_seed.props.growing]) : '' }}  
        </div>
      </div>

      <div class="resi" v-if="data?.item_harvest?.item_review_seed?.props?.resistance">
        <div class="header">{{ $t('harvest_view_resistance') }}</div>
        <div>
          {{ $constants.harvestResistance[data.item_harvest.item_review_seed.props.resistance] ? $t($constants.harvestResistance[data.item_harvest.item_review_seed.props.resistance]) : '' }}  
        </div>
      </div>


    </div>

    <div 
      class="text" 
      v-html="data.item_harvest.item_review_seed.text"
      ref="refCommentText"
      ></div>

      <!-- {{ data.item_harvest }} -->

    <GeneralTranslateButton
      v-if="data.item_harvest.item_review_seed.text && refCommentText"
      :el="refCommentText"
      :text="data.item_harvest.item_review_seed.text"
      :id="data.item_harvest.item_review_seed.id"
      :type="'harvest_seed'"
      :locale="$i18n.locale"
      />
 
  </div>
  
</template>

<script setup>


const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const refCommentText = ref(null);


</script>

<style scoped>
  

.seed_box{
  display: flex;
}
.seed_box .brand{
  width: calc(100% - 600px);
  display: flex;
  align-items: center;
}
.seed_box .brand .lg{
  width: 70px;
    flex-shrink: 0;
    margin-right: 15px;
}
.seed_box .grow{
  width: 300px;text-align: center;

  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.seed_box .resi{
  width: 300px;text-align: center;

  display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.seed_box .header{
    font-size: 1.2rem;
    font-weight: bold;
}
.text_strain{
  margin-top: 20px;

}
.text{
  margin-top: 1rem;
  white-space: pre-wrap;
}

@container pb (max-width: 768px) {

  .text_strain{
    text-align: center;
  }
  .seed_box{
    flex-wrap: wrap;
  }

  .seed_box .brand{
    width: 100%;
    margin: 0 auto;
    margin-bottom: 1rem;

    display: flex;
    justify-content: center;
  }
  .seed_box .grow{
    width: 50%;
  }
  .seed_box .resi{
      width: 50%;
  }
  .seed_box .brand .content{
      text-align: center;
      max-width: 310px;
  }
  .seed_box .brand .item{
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }
}

@container pb (max-width: 470px) {
  .seed_box .grow{
    width: 100%;
  }
  .seed_box .resi{
      width: 100%;
  }
}

</style>
