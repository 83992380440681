<template>
  
  <div class="parameters_bx">

    <div class="divider">
      {{ $t('harvest_view_the_outcome') }}            
    </div>

    <div class="parameters">

      <WeekViewConditionsItem                 
        v-for="(par, par_key) in parameters"
        :id="par.id"
        :tophint="par.tophint"
        :hint="par.hint"
        :value="par.value"
        :before-value="par.beforeValue"
        :after-value="par.afterValue"
        :ico="par.ico"   
        :unit="par.unit" 
        :measure="par.measure"
        :wiki="false"
        @change_measure="par.onChange"
        />
 
      
    </div>

  </div>
  
</template>

<script setup>

const { t } = useI18n();

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  active: {
    type: Boolean,
    default: false
  }
})
 

const parameters = computed(() => {
  var list = [];

  var week = {
    hint: t('universal_type_faza_har'),
    value: props.data.days,
    beforeValue: t('conditions_week'),
    afterValue: null,
    ico: 'week',
    unit: null,
    measure: null,
    onChange: null
  };
  list.push(week);



  if(props.data.props.harvest_weight){
    list.push({
      tophint: t('harvest_view_parameters_whats_on_scales'),
      hint: t('harvest_view_parameters_bud_dry_weight'),
      value: props.data.props.harvest_weight,
      beforeValue: null,
      afterValue: null,
      ico: 'n_weight',
      id: 'n_weight',
      unit: 'weight',
      measure: useAuth().getWeight(),
      onChange: useAuth().setWeight
    });
  }

  if(props.data.props.harvest_wet_weight){
    list.push({
      tophint: t('harvest_view_parameters_whats_on_scales'),
      hint: t('harvest_view_parameters_bud_wet_weight'),
      value: props.data.props.harvest_wet_weight,
      beforeValue: null,
      afterValue: null,
      ico: 'n_wet_weight',
      id: 'n_wet_weight',
      unit: 'weight',
      measure: useAuth().getWeight(),
      onChange: useAuth().setWeight
    });
  }

  if(props.data.props.harvest_plant){
    list.push({
      tophint: t('harvest_view_parameters_harvested'),
      hint: t('harvest_view_parameters_number_of_plants'),
      value: props.data.props.harvest_plant,
      beforeValue: null,
      afterValue: null,
      ico: 'n_plant',
      id: 'n_plant',
      unit: 'plant',
      measure: null,
      onChange: null
    });
  }

  if(props.data.props.harvest_space){
    list.push({
      tophint: t('harvest_view_parameters_grow_room'),
      hint: t('harvest_view_parameters_grow_room_size'),
      value: props.data.props.harvest_space,
      beforeValue: null,
      afterValue: null,
      ico: 'n_space',
      id: 'n_space',
      unit: 'square',
      measure: useAuth().getLength(),
      onChange: useAuth().setLength
    });
  }


 

  return list;


});



</script>

<style scoped>
 
 
.setup_ico{
  display: inline-block;
width: 65px;
height: 65px;
background-size: cover;
background-repeat: no-repeat;
}

.setup_ico.setup_ico_week{ background-image: url(/images/setup/setup_icons-04.svg); }
.setup_ico.setup_ico_height{ background-image: url(/images/setup/setup_icons-06.svg); }
.setup_ico.setup_ico_light{ background-image: url(/images/setup/setup_icons-05.svg); }
.setup_ico.setup_ico_air_temp{ background-image: url(/images/setup/setup_icons-03.svg); }
.setup_ico.setup_ico_ph{ background-image: url(/images/setup/setup_icons-02.svg); }
.setup_ico.setup_ico_ppm{ background-image: url(/images/setup/setup_icons-13.svg); }
.setup_ico.setup_ico_air_hum{ background-image: url(/images/setup/setup_icons-08.svg); }
.setup_ico.setup_ico_smell{ background-image: url(/images/setup/setup_icons-01.svg); }
.setup_ico.setup_ico_solution_temp{ background-image: url(/images/setup/setup_icons-14.svg); }
.setup_ico.setup_ico_substrate_temp{ background-image: url(/images/setup/setup_icons-07.svg); }
.setup_ico.setup_ico_night_air_temp{ background-image: url(/images/setup/setup_icons-09.svg); }
.setup_ico.setup_ico_pot_size{ background-image: url(/images/setup/setup_icons-10.svg); }
.setup_ico.setup_ico_lamp_distance{ background-image: url(/images/setup/setup_icons-11.svg); }
.setup_ico.setup_ico_co2{ background-image: url(/images/setup/setup_icons-12.svg); }
.setup_ico.setup_ico_watering_volume{ background-image: url(/images/setup/setup_icons-15.svg); }

.parameters{
display: flex;
display: -webkit-flex;
align-items: center;
-webkit-align-items: center;
justify-content: center;
-webkit-justify-content: center;
flex-wrap: wrap;
-webkit-flex-wrap: wrap;
}



.post_methods {
display: flex;
flex-wrap: wrap;
overflow: hidden;
/* height: 50px; */
align-items: flex-start;
position: relative;
/* padding-right: 30px; */
/* margin-top: 10px; */
justify-content: center;
}
.post_methods .method {
cursor: help;
display: flex;
align-items: center;
justify-content: flex-start;
margin: 10px;
}
.post_methods .method .info{
display: flex;
flex-direction: column;
/*width: calc(100% - 46px);*/
}
.post_methods .method .info .name{
color: var(--un-text-color);
/*max-width: 120px;*/
font-weight: bold;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.post_methods .method .info .title{
color: gray;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
/*max-width: 120px;*/
}


.post_methods .ic{
display: inline-block;
width: 40px;
height: 40px;
background-size: cover;
background-repeat: no-repeat;
margin-right: 10px;
}

/* .post_methods .ic-method-1{ background-image: url(/images/setup_diary/setup_diary_icons-0.svg); } */
.post_methods .ic-method-2{ background-image: url(/images/setup_diary/setup_diary_icons-09.svg); }
.post_methods .ic-method-3{ background-image: url(/images/setup_diary/setup_diary_icons-16.svg); }
.post_methods .ic-method-4{ background-image: url(/images/setup_diary/setup_diary_icons-10.svg); }
.post_methods .ic-method-5{ background-image: url(/images/setup_diary/setup_diary_icons-17.svg); }
.post_methods .ic-method-6{ background-image: url(/images/setup_diary/setup_diary_icons-13.svg); }
.post_methods .ic-method-7{ background-image: url(/images/setup_diary/setup_diary_icons-14.svg); }
.post_methods .ic-method-8{ background-image: url(/images/setup_diary/setup_diary_icons-11.svg); }
.post_methods .ic-method-9{ background-image: url(/images/setup_diary/setup_diary_icons-12.svg); }
/* .post_methods .ic-method-10{ background-image: url(/images/setup_diary/setup_diary_icons-18.svg); } */
.post_methods .ic-method-11{ background-image: url(/images/setup_diary/setup_diary_icons-15.svg); }



</style>
