<template>

  <div class="actions" v-if="useAuth().getId() == owner">    

    <UiButtonGroup>

      <template v-if="isHarvest">

        <UiButton
          tag="NuxtLink"
          ico="icon-pencil"      
          :name="$t('diary_info_button_edit_week')" 
          :to="'/diaries/' + diarylink + '/edit/week/' + weekid + ''"
          />
        <UiButton
          tag="NuxtLink"
          ico="icon-pencil"        
          :name="$t('harvest_view_button_edit_smoke')"
          :to="'/diaries/' + diarylink + '/edit/smoke/' + weekid + ''"
          />

      </template>
      <template v-else>
  
        <UiButton
          tag="NuxtLink"
          ico="icon-pencil"   
          :name="$t('diary_info_button_edit_week')"
          @click.native="emits('edit_week')" 
          :to="'/diaries/' + diarylink + '/edit/week/' + weekid + ''"
          />
      </template>

      <UiButton      
        @click="$emit('remove')"
        ico="icon-remove"
        name="Remove week"            
        />    

    </UiButtonGroup>        

  </div>
  
</template>

<script setup>
 

const props = defineProps({
  diarylink: {
    type: String
  },
  owner: {
    type: Number
  },
  weekid: {
    type: Number
  },
  isHarvest: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(['edit_week', 'remove']);

 
</script>

<style scoped>
 
 .actions{
   display: flex;
   justify-content: center;
 }

@container pb (max-width: 600px) {
  .actions{
    display: none;
  }
}
</style>
