<template>
  
  <div class="parameters_bx">

    <div class="divider">
      {{ $t('week_view_conditions_title') }}            
    </div>

    <div class="parameters">

      <WeekViewConditionsItem                 
        v-for="(par, par_key) in (!useAuth().isAuth() ? parameters.slice(0, 3) : parameters)"
        :id="par.id"
        :hint="par.hint"
        :value="par.value"
        :before-value="par.beforeValue"
        :after-value="par.afterValue"
        :ico="par.ico"   
        :unit="par.unit" 
        :measure="par.measure"
        :wiki="par.wiki"
        @change_measure="par.onChange"
        />


      <div class="wlg_parameters" v-if="!useAuth().isAuth()">      
        <div 
          v-for="(par, par_key) in parameters.slice(0, 3)"
          :class="'setup_ico setup_ico_' + par.ico"
          >
        </div>
        <b>{{ parameters?.length }}+ conditions after</b>
        <div @click="useAuth().goToLogin()" class="btn primary small">
          Login
        </div>
      </div>
 
      
    </div>

  </div>
  
</template>


<script setup>

const { t } = useI18n();
const { $constants } = useNuxtApp();

const props = defineProps({
  days: {
    type: Number,
    required: true
  },
  faza: {
    type: Number,
    required: true
  },
  data: {
    type: Object,
    required: true
  },
  props: {
    type: Object,
    required: false
  }
});

const parameters = computed(() => {
  var list = [];

  var week = {
    wiki: false,
    hint: props.faza == 0 ? t('universal_type_faza_veg') : t('universal_type_faza_flo'),
    value: props.days,
    beforeValue: t('conditions_week'),
    afterValue: null,
    ico: 'week',
    unit: null,
    measure: null,
    onChange: null
  };
  list.push(week);


  if(props.props){
    if(props.props.height){
      var height = {
        hint: t('conditions_height'),
        value: props.props.height,
        beforeValue: null,
        afterValue: null,
        ico: 'height',
        id: 'height',
        unit: 'length',
        measure: useAuth().getLength(),
        onChange: useAuth().setLength
      };
      list.push(height);
    }

    if(props.props.light){
      var light = {
        hint: t('conditions_light_schedule'),
        value: props.props.light,
        beforeValue: null,
        afterValue: t('set_hrs'),
        ico: 'light',
        id: 'light',
        unit: null,
        measure: null,
        onChange: null
      };
      list.push(light);
    }

    if(props.props.air_temp){
      var air_temp = {
        hint: t('conditions_day_air_temperature'),
        value: props.props.air_temp,
        beforeValue: null,
        afterValue: null,
        ico: 'air_temp',
        id: 'air-temp',
        unit: 'temperature',
        measure: useAuth().getTemperature(),
        onChange: useAuth().setTemperature
      };
      list.push(air_temp);
    }

    if(props.props.ph){
      var ph = {
        hint: t('conditions_ph'),
        value: props.props.ph,
        beforeValue: null,
        afterValue: null,
        ico: 'ph',
        id: 'ph',
        unit: null,
        measure: null,
        onChange: null
      };
      list.push(ph);
    }

    if(props.props.smell){
      var smell = {
        hint: t('conditions_smell'),
        value: null,
        beforeValue: t($constants.postSmell[props.props.smell]),
        afterValue: null,
        ico: 'smell',
        id: 'smell',
        unit: null,
        measure: null,
        onChange: null
      };
      list.push(smell);
    }

    if(props.props.ppm){
      var ppm = {
        hint: useAuth().getTds() == 'ppm' ? t('conditions_tds') : t('conditions_ec'),
        value: props.props.ppm,
        beforeValue: null,
        afterValue: null,
        ico: 'ppm',
        id: 'ppm',
        unit: 'tds',
        measure: useAuth().getTds(),
        onChange: useAuth().setTds
      };
      list.push(ppm);
    }

    if(props.props.air_hum){
      var air_hum = {
        hint: t('conditions_humidity'),
        value: props.props.air_hum,
        beforeValue: null,
        afterValue: '%',
        ico: 'air_hum',
        id: 'air-hum',
        unit: null,
        measure: null,
        onChange: null
      };

      list.push(air_hum);
    }

    if(props.props.solution_temp){
      var solution_temp = {
        hint: t('conditions_solution_temp'),
        value: props.props.solution_temp,
        beforeValue: null,
        afterValue: null,
        ico: 'solution_temp',
        id: 'solution-temp',
        unit: 'temperature',
        measure: useAuth().getTemperature(),
        onChange: useAuth().setTemperature
      };
      list.push(solution_temp);
    }

    if(props.props.substrate_temp){
      var substrate_temp = {
        hint: t('conditions_substrate_temp'),
        value: props.props.substrate_temp,
        beforeValue: null,
        afterValue: null,
        ico: 'substrate_temp',
        id: 'substrate-temp',
        unit: 'temperature',
        measure: useAuth().getTemperature(),
        onChange: useAuth().setTemperature
      };
      list.push(substrate_temp);
    }

    if(props.props.night_air_temp){
      var night_air_temp = {
        hint: t('conditions_night_air_temp'),
        value: props.props.night_air_temp,
        beforeValue: null,
        afterValue: null,
        ico: 'night_air_temp',
        id: 'night-air-temp',
        unit: 'temperature',
        measure: useAuth().getTemperature(),
        onChange: useAuth().setTemperature
      };
      list.push(night_air_temp);
    }

    if(props.props.pot_size){
      var pot_size = {
        hint: t('conditions_pot_size'),
        value: props.props.pot_size,
        beforeValue: null,
        afterValue: null,
        ico: 'pot_size',
        id: 'pot-size',
        unit: 'volume',
        measure: useAuth().getVolume(),
        onChange: useAuth().setVolume
      };
      list.push(pot_size);
    }

    if(props.props.watering_volume){
      var watering_volume = {
        hint: t('conditions_watering_volume'),
        value: props.props.watering_volume,
        beforeValue: null,
        afterValue: null,
        ico: 'watering_volume',
        id: 'watering-volume',
        unit: 'volume',
        measure: useAuth().getVolume(),
        onChange: useAuth().setVolume
      };
      list.push(watering_volume);
    }

    if(props.props.lamp_distance){
      var lamp_distance = {
        hint: t('conditions_lamp_to_plant'),
        value: props.props.lamp_distance,
        beforeValue: null,
        afterValue: null,
        ico: 'lamp_distance',
        id: 'lamp-distance',
        unit: 'length',
        measure: useAuth().getLength(),
        onChange: useAuth().setLength
      };
      list.push(lamp_distance);
    }

    if(props.props.co2){
      var co2 = {
        hint: t('conditions_co2'),
        value: props.props.co2,
        beforeValue: null,
        afterValue: 'PPM',
        ico: 'co2',
        id: 'co2',
        unit: 'ppm',
        measure: null,
        onChange: null
      };
      list.push(co2);
    }
  }



  return list;


});



// const parametersView = computed(() => {

//   if(!useAuth().isAuth()){
//     return parameters.value.slice(0, 3);
//   }else{
//     return parameters.value;
//   }
    
// });

// const parametersCroped = computed(() => {

//   if(!useAuth().isAuth()){
//     return parameters.value.slice(3);
//   }else{
//     return [];
//   }
    
// });



</script>

<style scoped>
 
 
.setup_ico{
  display: inline-block;
width: 65px;
height: 65px;
background-size: cover;
background-repeat: no-repeat;
}

.setup_ico.setup_ico_week{ background-image: url(/images/setup/setup_icons-04.svg); }
.setup_ico.setup_ico_height{ background-image: url(/images/setup/setup_icons-06.svg); }
.setup_ico.setup_ico_light{ background-image: url(/images/setup/setup_icons-05.svg); }
.setup_ico.setup_ico_air_temp{ background-image: url(/images/setup/setup_icons-03.svg); }
.setup_ico.setup_ico_ph{ background-image: url(/images/setup/setup_icons-02.svg); }
.setup_ico.setup_ico_ppm{ background-image: url(/images/setup/setup_icons-13.svg); }
.setup_ico.setup_ico_air_hum{ background-image: url(/images/setup/setup_icons-08.svg); }
.setup_ico.setup_ico_smell{ background-image: url(/images/setup/setup_icons-01.svg); }
.setup_ico.setup_ico_solution_temp{ background-image: url(/images/setup/setup_icons-14.svg); }
.setup_ico.setup_ico_substrate_temp{ background-image: url(/images/setup/setup_icons-07.svg); }
.setup_ico.setup_ico_night_air_temp{ background-image: url(/images/setup/setup_icons-09.svg); }
.setup_ico.setup_ico_pot_size{ background-image: url(/images/setup/setup_icons-10.svg); }
.setup_ico.setup_ico_lamp_distance{ background-image: url(/images/setup/setup_icons-11.svg); }
.setup_ico.setup_ico_co2{ background-image: url(/images/setup/setup_icons-12.svg); }
.setup_ico.setup_ico_watering_volume{ background-image: url(/images/setup/setup_icons-15.svg); }

.parameters{
display: flex;
display: -webkit-flex;
align-items: center;
-webkit-align-items: center;
justify-content: center;
-webkit-justify-content: center;
flex-wrap: wrap;
-webkit-flex-wrap: wrap;
}



.post_methods {
display: flex;
flex-wrap: wrap;
overflow: hidden;
/* height: 50px; */
align-items: flex-start;
position: relative;
/* padding-right: 30px; */
/* margin-top: 10px; */
justify-content: center;
}
.post_methods .method {
cursor: help;
display: flex;
align-items: center;
justify-content: flex-start;
margin: 10px;
}
.post_methods .method .info{
display: flex;
flex-direction: column;
/*width: calc(100% - 46px);*/
}
.post_methods .method .info .name{
color: var(--un-text-color);
/*max-width: 120px;*/
font-weight: bold;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.post_methods .method .info .title{
color: gray;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
/*max-width: 120px;*/
}


.post_methods .ic{
display: inline-block;
width: 40px;
height: 40px;
background-size: cover;
background-repeat: no-repeat;
margin-right: 10px;
}

/* .post_methods .ic-method-1{ background-image: url(/images/setup_diary/setup_diary_icons-0.svg); } */
.post_methods .ic-method-2{ background-image: url(/images/setup_diary/setup_diary_icons-09.svg); }
.post_methods .ic-method-3{ background-image: url(/images/setup_diary/setup_diary_icons-16.svg); }
.post_methods .ic-method-4{ background-image: url(/images/setup_diary/setup_diary_icons-10.svg); }
.post_methods .ic-method-5{ background-image: url(/images/setup_diary/setup_diary_icons-17.svg); }
.post_methods .ic-method-6{ background-image: url(/images/setup_diary/setup_diary_icons-13.svg); }
.post_methods .ic-method-7{ background-image: url(/images/setup_diary/setup_diary_icons-14.svg); }
.post_methods .ic-method-8{ background-image: url(/images/setup_diary/setup_diary_icons-11.svg); }
.post_methods .ic-method-9{ background-image: url(/images/setup_diary/setup_diary_icons-12.svg); }
/* .post_methods .ic-method-10{ background-image: url(/images/setup_diary/setup_diary_icons-18.svg); } */
.post_methods .ic-method-11{ background-image: url(/images/setup_diary/setup_diary_icons-15.svg); }


.wlg_parameters{
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 1rem;
  justify-content: center;
  width: 100%;
}

.wlg_parameters .setup_ico{
  width: 23px;
  height: 23px;
  background-size: cover;
  background-repeat: no-repeat;
}

</style>
