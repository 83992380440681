<template>

  <div 
    class="btn_group"
    >
    <slot>
      
    </slot>
  </div>
  
</template>

<script>
 

export default {  
  props:[    
      
  ], 
  data () {
    return {    
      
    }
  },
  created() {    
    
  },
  computed: { 
  },
  methods: {    
  
  }
}
</script>

<style scoped lang="css">

 
</style>
