<template>
  
  <div v-if="props.user && props.week?.item_harvest?.smoke_comment" class="week_comment">

    <div class="divider">
      Smoke review
    </div>


    <div class="text" ref="refWeekText">{{ props.week.item_harvest.smoke_comment }} </div>

    
    <!-- <GeneralTranslateButton
      v-if="props.week.text && $i18n.locale != 'en' && refWeekText"
      :el="refWeekText"
      :text="props.week.text"
      :id="props.week.id"
      :type="'week'"
      :locale="$i18n.locale"
      /> -->
  
  </div>
  
</template>

<script setup>
  
const props = defineProps({
  week: {
    type: Object,
    required: true
  },
  user: {
    type: Object,
    required: true
  }
})
const refWeekText = ref(null);

</script>




<style scoped>
 

.label_author{  
  display: flex;
  align-items: center;
  flex-shrink: 0;
}
.label_author .detail{
margin-left: 6px;
}

.label_author .user_avatar{
  border-radius: 50px;
  width: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.text{
  white-space: pre-wrap;
  margin-bottom: 0.5rem;
}

@container pb (max-width: 768px) {

  .divider{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .divider .title{
    width: 100%;
  }

  .divider::before, .divider::after{
    display:none;
  }
}
</style>
