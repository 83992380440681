<template>
   
   <div class="nutrients_content" v-if="nutrients?.length">

    <div class="divider">{{ $t('week_view_nutrients_title') }}</div>

    <div class="toggleNutrientsWeek">

      <div class="measure_toggle">

        <template
          v-for="(opt, opt_key) in units"
          >
          <div 
            :class="'item' + (useAuth().getNutrient() == opt.id ? ' active ' : '')"
            @click="useAuth().setNutrient(opt.id)"
            >            
            {{ opt.name ? $t(opt.name) : '' }}              
          </div>
        </template> 
         
      </div>

    </div>


    <div :class="{'post_ferts' : true, 'nutr_shadow': !useAuth().isAuth() && nutrients.length  > 3}"> 

      <client-only>
        <div class="donut" v-if="nutrients.length > 1">
 
            <UiChartDonut 
              :data="sections" 
              :active-id="avtiveNutrinetId"
              :size="10" 
              :size-px="'200px'"
              :strokeWidth="1">
              <div class="selected_chart_title">{{ sectionPc }}</div> 
              <div class="selected_chart_hint">{{ sectionName }}</div> 
            </UiChartDonut>


        </div>
      </client-only>
      
    


      <div class="fert_items">


        <template
          v-for="nutrient in (useAuth().isAuth() ? nutrients : nutrients.slice(0, 3))"
          >


          <template
            v-if="!nutrient.id"
            >

            <div 
              class="fert_item"
              @mouseover="avtiveNutrinetId = nutrient.ref_id"
              @mouseleave="avtiveNutrinetId = null"
              >
              
            
              <div class="fert_photo">              
                <i class="icon-lab"></i>
              </div>
              <div class="fert_name">
                <div>
                  {{ nutrient.name ? nutrient.name : nutrient.item_brand.name }}
                </div>
              </div>
              <div class="fert_graph">
                <div class="fert_sel" :style="'width: ' + getChartPercents(nutrient.val) + '%; background-color:' + getColorByNumber(nutrient.ref_id) + ';'"></div>
              </div>
              <div class="fert_val">
                
                {{ $convert.universal('nutrient', useAuth().getNutrient(), nutrient.val) }} 
                {{ $constants.nutrientsUnit[useAuth().getNutrient()] ? $t($constants.nutrientsUnit[useAuth().getNutrient()]) : '' }}              
              </div>

            </div>
          </template>

          <template
            v-else-if="nutrient"
            >
            <NuxtLink 
              class="fert_item" 
              @mouseover="avtiveNutrinetId = nutrient.ref_id"
              @mouseleave="avtiveNutrinetId = null"
              :to="nutrient.link">            
              <div class="fert_photo">
                <i v-if="nutrient.id == '-1'" class="icon-nutrient"></i> 
                <img 
                  v-else-if="nutrient.avatar_little"
                  :src="nutrient.avatar_little" 
                  :alt="nutrient.name + ' - ' + nutrient.item_brand.name" 
                  >            
                <img 
                  v-else-if="nutrient.item_brand && nutrient.item_brand.logo_s"
                  :src="nutrient.item_brand.logo_s" 
                  :alt="nutrient.name + ' - ' + nutrient.item_brand.name" 
                  >      
              </div>

              <div class="fert_name">
                <div>{{ nutrient.name }}</div>
              </div>
              <div class="fert_graph">
                <div class="fert_sel" :style="'width: ' + getChartPercents(nutrient.val) + '%; background-color:' + getColorByNumber(nutrient.ref_id) + ';'"></div>
              </div>
              <div class="fert_val">
                {{ $convert.universal('nutrient', useAuth().getNutrient(), nutrient.val) }} 

                {{ useAuth().getNutrient() }}  
              </div>

            </NuxtLink>

          </template>



        </template>

  

        
      </div>



    </div>

    <div class="wlg_parameters" v-if="!useAuth().isAuth() && nutrients.length  > 3">      
      <b>{{ nutrients.length - 3 }}+ nutrients after</b>
      <div @click="useAuth().goToLogin()" class="btn primary small">
        Login
      </div>
    </div>

  

  </div>
  
</template>

<script setup>

const { t } = useI18n();
const { $constants, $convert } = useNuxtApp();

const props = defineProps({
  nutrients: {
    type: Array
  }
});

// const sectionPc = ref('');
// const sectionName = ref('');
const avtiveNutrinetId = ref(null);

const units = computed(() => $constants.units.nutrient);

const sectionsTotal = computed(() => {
  var total = 0;            
  if(!props.nutrients) return total;
  for(var nut of props.nutrients){
    // total += nut.val;
    total += Math.round(Number(nut.val),2);
  }
  // return Math.round(total, 2);
  return $convert.universal('nutrient', useAuth().getNutrient(), total);
  return total;
  // return Math.round(total, 3);
});

const sections = computed(() => {
  var ret = [];
  if(!props.nutrients) return ret;   
 
  ret = props.nutrients.map(item => {
    return {
      id: item.ref_id,
      value: Math.round(item.val, 2), 
      label: item.name
    };
  });

  
  return ret;
});

const max_val = computed(() => {
  var val = 0;
  if(!props.nutrients) return val;
  for(var nut of props.nutrients)
    if(nut.val > val)
      val = nut.val;   
      // console.log(val); 
  return val;
});

const min_val = computed(() => {
  var val = 10000;
  if(!props.nutrients) return val;
  for(var nut of props.nutrients)
    if(nut.val < val)
      val = nut.val;    
  return val;
});

const sectionPc = computed(() => {
  return sectionsTotal.value + ' ' + ($constants.nutrientsUnit[useAuth().getNutrient()] ? t($constants.nutrientsUnit[useAuth().getNutrient()]) : '' );
});

const sectionName = computed(() => {
  return 'Total';
});

// const handleSectionClick = (el) => {
  // sectionPc.value = sel.value + ' ' + ($constants.nutrientsUnit[useAuth().getNutrient()] ? t($constants.nutrientsUnit[useAuth().getNutrient()]) : '' );
  // sectionName.value = sel.label;            
// };

// const setDefaultChartLabel = () => {
//   sectionPc.value = sectionsTotal.value + ' ' + ($constants.nutrientsUnit[useAuth().getNutrient()] ? t($constants.nutrientsUnit[useAuth().getNutrient()]) : '' );
//   sectionName.value = 'Total';
// };

const getChartPercents = (val) => {
  return val * 100/max_val.value;
};



watch (() => props.nutrients, (newVal, oldVal) => {      
  // setDefaultChartLabel();          
});

onMounted(() => {
  // setDefaultChartLabel();
});


</script>

<style scoped>
 
 


.post_ferts{
    display: flex;
    align-items: center;
    width: 100%;
}
 

.post_ferts .fert_cont{
position: inherit;

}
.post_ferts .infoData{
margin-top: calc(50% - 43px);
}
.post_ferts .centerData{
display: inline-block; font-family:  GlobRegular, sans-serif;     margin-top: -300px; z-index: 100; border-radius: 200px; margin-left: -100px; position: absolute; text-align:center;width: 200px;height:200px; background-color: white; padding: 10px; pointer-events: none;
}

.post_ferts .fert_items{
  width: 100%;
  flex-shrink: 1;
}
.post_ferts .fert_item{
display: table;
width: 100%;
height: 40px;
}

.post_ferts .fert_items.hight_active .fert_item{
opacity: 0.5;
}
.post_ferts .fert_items.hight_active .fert_item.active{
opacity: 1;
}

.post_ferts .charts_content{

}
.post_ferts .donut{  
  height: auto;
  flex-shrink: 0;
  width: 280px;
  margin-right: 2rem;
} 
.post_ferts .fert_item .fert_photo{
display: table-cell;
width: 60px;
text-align: center;
vertical-align: middle;
padding-right: 9px;
}
.post_ferts .fert_item .fert_photo img {
max-height: 40px;
max-width: 50px;
    text-align: center;
    margin: 0 auto;
}
.post_ferts .fert_item .fert_photo i {
font-size: 1.6rem;
color: #96be2a;
}
.post_ferts .fert_item .fert_name{
display: table-cell;
width: 150px;
text-align: left;
vertical-align: middle;
max-width: 150px;
/* font-size: 1rem; */
color: var(--un-text-color);
}
.post_ferts .fert_item .fert_name a{
color: black;
}

.post_ferts .fert_item .fert_name div{
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

.post_ferts .fert_graph{
display: table-cell;
text-align: left;
vertical-align: middle;
margin-left: 10px;
cursor: pointer;
}
.toggleNutrientsWeek{
text-align: right;
margin-bottom: 20px;
}
.post_ferts .fert_val{
display: table-cell;
text-align: right;
vertical-align: middle;
width: 100px;
/* font-size: 1rem; */
}
.post_ferts .fert_graph .fert_sel{
background: rgb(90 174 96);
display: inline-block;
min-width: 1px;
height: 15px;
border-radius: 3px;
}
.post_ferts .fert_self{
background: url("/images/ico_params_post/fert.png");
background-size: 100%;
background-repeat: no-repeat;
width: 24px;
height: 25px;
display: inline-block;
vertical-align: middle;
opacity: 0.8;
margin-right: 5px;
}

.post_ferts .fert_graph .fert_sel_vl{
display: inline-block;
position: absolute;
margin-left: 5px;
margin-top: 4px;
color: white;

}





/* MEASURE */

.measure_toggle{
display: inline-block;
white-space: nowrap;
vertical-align: text-bottom;
z-index: 1;
position: relative;
}
.measure_toggle .item{
display: inline-block!important;
    font-size: 0.8rem;
    text-transform: none;
    text-transform: initial;
    border: 1px var(--un-element-color-gray) solid;
    color: var(--un-text-color-gray-light);
    padding: 3px 8px;
    line-height: normal;
    vertical-align: bottom;
    min-width: 30px;
    text-align: center;
}
.measure_toggle .item:first-child{
border-radius: 3px 0px 0px 3px;
}
.measure_toggle .item:last-child{
border-radius: 0px 3px 3px 0px;
}
.measure_toggle .item:not(.active){
cursor: pointer;
}

.measure_toggle .item.active{

  border: 1px var(--un-element-color) solid;
color: var(--un-text-color);
}


  
@container pb (max-width: 768px) {

    
  .post_ferts{
    display: flex; 
    flex-direction: column;
  }
    
  .post_ferts .fert_items{
    width: 100%;
    margin-top: 1rem;
  }

  .post_ferts .donut{  

    width: 100%;
  }
    
}

/* chart */

.selected_chart_title{
  font-size: 1.2rem;
  font-weight: bold;
}

.selected_chart_hint{

}


/* no auth */



.nutr_shadow {
  position: relative;
  max-height: 170px;
  overflow: hidden;
  margin-bottom: 3rem;
}

.nutr_shadow::after {
  content: ' ';
  bottom: 0;
  right: 0;
  position: absolute;
  height: 50%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, var(--un-background-color-transparent) 0%, var(--un-background-color) 100%);
}

.wlg_parameters{
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: -3rem;
  justify-content: center;
}



</style>



