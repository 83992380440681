<template>
  
  <div class="calc" v-if="ws || gw || gm || gp || ps">
  
    <div class="cl" v-if="gw">
      <div class="vl">
        {{ gw }}
      </div>
      <div class="ut">
        {{ $constants.weight[useAuth().getWeight()] ? $t($constants.weight[useAuth().getWeight()]) : '' }}
        /
        {{ $t('universal_unit_watt') }} 
      </div>
    </div>
  
    <div class="cl" v-if="gm">
      <div class="vl">
        {{ gm }}
      </div>
      <div class="ut">
        {{ $constants.weight[useAuth().getWeight()] ? $t($constants.weight[useAuth().getWeight()]) : '' }}
        /
        {{ $constants.metricSquare[useAuth().getLength()] ? $t($constants.metricSquare[useAuth().getLength()]) : '' }}
      </div>
    </div>
  
    <div class="cl" v-if="gp">
      <div class="vl">
        {{ gp }}
      </div>
      <div class="ut">
        {{ $constants.weight[useAuth().getWeight()] ? $t($constants.weight[useAuth().getWeight()]) : '' }} 
        / 
        {{ $t('universal_plurals_plant') }} 
      </div>
    </div>
  
    <div class="cl" v-if="ps">
      <div class="vl">
        {{ ps }}
      </div>
      <div class="ut">
        {{ $t('universal_plurals_plant') }} 
        /
        {{ $constants.metricSquare[useAuth().getLength()] ? $t($constants.metricSquare[useAuth().getLength()]) : '' }}
      </div>
    </div>
  
    <div class="cl" v-if="ws">
      <div class="vl">
        {{ ws }}
      </div>
      <div class="ut">
        {{ $t('universal_unit_watt') }} 
        /
        {{ $constants.metricSquare[useAuth().getLength()] ? $t($constants.metricSquare[useAuth().getLength()]) : '' }}
      </div>
    </div>

  </div>
  
</template>

<script setup>


const { t } = useI18n();

const { $convert } = useNuxtApp();

const props = defineProps({
  weight: {
    type: Number,
    default: 0
  },
  wetweight: {
    type: Number,
    default: 0
  },
  plant: {
    type: Number,
    default: 0
  },
  space: {
    type: Number,
    default: 0
  },
  watt: {
    type: Number,
    default: 0
  }
})


const gw = computed (() => {
  if(!props.weight) return false;
  if(!props.watt) return false;
  var w = $convert.universal('weight', useAuth().getWeight(), props.weight);
  if(!w) return false;
  return Math.round(w/props.watt*100)/100;
});

const gm = computed (() => {
  if(!props.weight) return false;
  if(!props.space) return false;
  var w = $convert.universal('weight', useAuth().getWeight(), props.weight);
  var s = $convert.universal('square', useAuth().getLength(), props.space);
  if(!w) return false;
  if(!s) return false;
  return Math.round(w/s*100)/100;
});

const gp = computed (() => {
  if(!props.weight) return false;
  if(!props.plant) return false;
  var w = $convert.universal('weight', useAuth().getWeight(), props.weight);
  if(!w) return false;
  return Math.round(w/props.plant*100)/100;
});

const ps = computed (() => {
  if(!props.plant) return false;
  if(!props.space) return false;
  var s = $convert.universal('square', useAuth().getLength(), props.space);
  if(!s) return false;
  return Math.round(props.plant/s*100)/100;
});

const ws = computed (() => {
  if(!props.watt) return false;
  if(!props.space) return false;
  var s = $convert.universal('square', useAuth().getLength(), props.space);
  if(!s) return false;
  return Math.round(props.watt/s*100)/100;
});


  
// export default {   
//   props: [
//     'weight',
//     'wetweight',
//     'plant',
//     'space',
//     'watt'
//   ],
//   components: {         
    
//   },  
//   data () {
//     return {            
//     }
//   },
//   created() {    
    
//   },
//   computed: { 
//     gw(){
//       if(!this.weight) return false;
//       if(!this.watt) return false;
//       var w = this.$convert.universal('weight', useAuth().getWeight(), this.weight);
//       if(!w) return false;
//       return Math.round(w/this.watt*100)/100;
//     },
//     gm(){
//       if(!this.weight) return false;
//       if(!this.space) return false;
//       var w = this.$convert.universal('weight', useAuth().getWeight(), this.weight);
//       var s = this.$convert.universal('square', useAuth().getLength(), this.space);
//       if(!w) return false;
//       if(!s) return false;
//       return Math.round(w/s*100)/100;
//     },
//     gp(){
//       if(!this.weight) return false;
//       if(!this.plant) return false;
//       var w = this.$convert.universal('weight', useAuth().getWeight(), this.weight);
//       if(!w) return false;
//       return Math.round(w/this.plant*100)/100;
//     },
//     ps(){
//       if(!this.plant) return false;
//       if(!this.space) return false;
//       var s = this.$convert.universal('square', useAuth().getLength(), this.space);
//       if(!s) return false;
//       return Math.round(this.plant/s*100)/100;
//     },
//     ws(){
//       if(!this.watt) return false;
//       if(!this.space) return false;
//       var s = this.$convert.universal('square', useAuth().getLength(), this.space);
//       if(!s) return false;
//       return Math.round(this.watt/s*100)/100;
//     },
//   },
//   methods: {
     
//   }
// }
</script>

<style scoped>
 
.calc{
  display: flex;
  justify-content: center;
  margin: 1rem;
  flex-wrap: wrap;
}
.cl{
  margin: 0 1rem;
  text-align: center;
  min-width: 90px;
}
.vl{
  font-size: 1.5rem;
  white-space: nowrap;
}
.ut{
  font-weight: bold;
  text-transform: uppercase;
}


@container pb (max-width: 600px) {
  
  .calc{
    margin: 0;
  }
  .calc .cl{
      width: 100%;
      margin: 5px 0px 5px 0px;
      justify-content: flex-start;
    -webkit-justify-content: flex-start;
    text-align: left;
  }


  .calc .cl{
    width: 100%;
      display: flex;
    align-items: center;
      margin-left: 5px;
    flex-direction: row-reverse;
  }
  .calc .vl {
    font-size: 1rem!important;
    white-space: nowrap;
    font-weight: bold;
    width: 100%;
  }
  .calc .ut{
    
    text-transform: inherit;   
     width: calc(58% + 17px);
    flex-shrink: 0;
  }

}
</style>
